import fragmentShader from 'raw-loader!./fragment/snowfall.glsl';
import vertexShader from 'raw-loader!./vertex/snowfall.glsl';
import { Color, AdditiveBlending } from 'three';

export const Snowfall = {
  uniforms: {
    color: { type: 'c', value: new Color(0xffffff) },
    height: { type: 'f', value: 10 },
    speedV: { type: 'f', value: 0.16 },
    speedH: { type: 'f', value: 0.5 },
    elapsedTime: { type: 'f', value: 0.0 },
    radius: { type: 'f', value: 0.06 },
    scale: { type: 'f', value: 0.5 },
    size: { type: 'f', value: -1 },
    opacity: { type: 'f', value: 0.5 },
    texture: { type: 't', value: null }
  },
  fragmentShader,
  vertexShader,
  transparent: true,
  blending: AdditiveBlending
};
