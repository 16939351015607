import React, { useMemo, useRef, useState, useEffect } from 'react';
import { Snowfall as Shader } from '../shaders/Snowfall';
import { useThree, useFrame, useLoader } from 'react-three-fiber';
import { useTexture } from '../../../hooks/use-loaded';
import { ShaderMaterial, Texture, TextureLoader } from 'three';

// https://codesandbox.io/s/shy-grass-i16fp from 14islands

interface Props {
  particles: number;
  width?: number;
  height?: number;
  depth?: number;
  enabled: boolean;
  timeout?: number;
  startingY?: () => number;
  rotation?: [number, number, number];
  position?: [number, number, number];
  texture: Texture;
}
const randCenter = (v: number) => {
  return v * (Math.random() - 0.5);
};

export const MainSnowfall = (props: any) => {
  const texture = useTexture('snowflake_texture');

  return <Snowfall {...props} texture={texture} />;
};

export const FourOhFourSnowfall = (props: any) => {
  const texture = useLoader(TextureLoader as any, require('../../../assets/images/textures/snowflake.png'));

  return <Snowfall {...props} texture={texture} />;
}

export const Snowfall = ({
  particles,
  width = 10,
  height = 10,
  depth = 10,
  enabled = false,
  timeout = 1500,
  rotation = [0, 0, 0],
  startingY = () => 0,
  position = [0,0,0],
  texture,
}: Props) => {
  const { size, clock: c } = useThree();

  const mat = useRef<ShaderMaterial>();

  const [start, setStart] = useState(() => c.elapsedTime);
  const [isEnabled, setIsEnabled] = useState(false);
  const uniqueness = useMemo(() => {
    const u = new Float32Array(particles);

    // push some uniqueness - because snowflakes...
    for (let i = 0; i < particles; i++) {
      u[i] = Math.random();
    }
    return u;
  }, [particles]);

  useEffect(() => {
    if (enabled) {
      // hax: wait for camera animation before starting the snow
      setTimeout(
        () => {
          setIsEnabled(enabled);
          setStart(c.getElapsedTime());
        },
        enabled ? timeout : 0
      );
    }
  }, [enabled]);

  const positions = useMemo(() => {
    const ps = new Float32Array(particles * 3);
    for (let i = 0; i < ps.length; i += 3) {
      // x
      ps[i] = randCenter(width);
      // y
      ps[i + 1] = startingY(); // Math.random() * height;
      // z
      ps[i + 2] = randCenter(depth);
    }

    return ps;
  }, [width, height, depth, particles]);

  useFrame(({ clock }) => {
    if (isEnabled && mat.current) {
      mat.current!.uniforms.elapsedTime.value = clock.elapsedTime - start;
    }
  });

  return (
    <points renderOrder={1} rotation={rotation} position={position}>
      <bufferGeometry attach="geometry">
        <bufferAttribute
          attachObject={['attributes', 'position']}
          count={positions.length / 3}
          array={positions}
          itemSize={3}
        />
        <bufferAttribute
          attachObject={['attributes', 'uniqueness']}
          count={uniqueness.length}
          array={uniqueness}
          itemSize={1}
        />
      </bufferGeometry>
      <shaderMaterial
        ref={mat}
        args={[Shader]}
        attach="material"
        uniforms-size-value={size.height * 0.06}
        uniforms-height-value={height}
        uniforms-texture-value={texture}
      />
    </points>
  );
};
