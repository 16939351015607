import { useContext, useMemo, useEffect } from 'react';
import { LoadingContext } from '../context/LoadingContext';
import { Object3D, Texture, Font } from 'three';
import { GLTF } from '../utils/GLTFLoader';

// @ts-ignore
import usePromise from 'react-promise-suspense';

// copy-pasted from the useLoader hook
const blackList = [
  'id',
  'uuid',
  'type',
  'children',
  'parent',
  'matrix',
  'matrixWorld',
  'matrixWorldNeedsUpdate',
  'modelViewMatrix',
  'normalMatrix',
];

export function prune(props: any) {
  const reducedProps = { ...props };
  // Remove black listed props
  blackList.forEach(name => delete reducedProps[name]);
  // Remove functions
  Object.keys(reducedProps).forEach(
    name => typeof reducedProps[name] === 'function' && delete reducedProps[name]
  );
  // Prune materials and geometries
  if (reducedProps.material) {
    reducedProps.material = prune(reducedProps.material);
  }
  if (reducedProps.geometry) {
    reducedProps.geometry = prune(reducedProps.geometry);
  }
  // Return cleansed object
  return reducedProps;
}

type GLTFExtended = GLTF & {
  __$: Object3D[];
};

export function useTexture<T = Texture>(id: string | string[]): T {
  const isArray = Array.isArray(id);

  const allIds: string[] = isArray ? (id as string[]) : [id as string];

  const { getTexture } = useContext(LoadingContext);

  const textures = usePromise(
    (ids: any) =>
      Promise.all(
        ids.map(async (i: string) => {
          const t = await getTexture(i);

          if (!t.name || (t.name && t.name.length === 0)) {
            t.name = i;
          }
          return t;
        })
      ),
    [allIds]
  );

  useEffect(() => () => textures.forEach((t: Texture) => t && t.dispose()), []);

  return ((isArray ? textures : textures[0]) as unknown) as T;
}

export function useFont(id: string): Font {
  const { getFont } = useContext(LoadingContext);

  const fnt = usePromise((f: string) => getFont(f), [id]);

  return fnt;
}

export function useModel(id: string): GLTFExtended {
  const { getModel } = useContext(LoadingContext);

  const gltf = usePromise(
    async (i: string) => {
      const model = await getModel(i);

      if (model !== null) {
        const objects: any[] = [];
        model.scene.traverse((props: any) => objects.push(prune(props)));
        (model as any).__$ = objects;
      }

      return model as GLTFExtended;
    },
    [id]
  );

  useEffect(
    () => () => {
      if (gltf && gltf.scene) {
        gltf.scene.dispose();
      }
    },
    []
  );

  return gltf;
}
